$(document).ready(function() {
	$('#fullpage').fullpage({
		//Navigation
		menu: '#menu',
		lockAnchors: false,
		//anchors:['firstPage', 'secondPage'],
		navigation: false,
		navigationPosition: 'right',
		//navigationTooltips: ['firstSlide', 'secondSlide'],
		showActiveTooltip: false,
		slidesNavigation: false,
		slidesNavPosition: 'bottom',

		//Scrolling
		css3: true,
		scrollingSpeed: 700,
		autoScrolling: true,
		fitToSection: true,
		fitToSectionDelay: 1000,
		scrollBar: false,
		easing: 'easeInOutCubic',
		easingcss3: 'ease',
		loopBottom: false,
		loopTop: false,
		loopHorizontal: true,
		continuousVertical: false,
		continuousHorizontal: false,
		scrollHorizontally: false,
		interlockedSlides: false,
		dragAndMove: false,
		offsetSections: false,
		resetSliders: false,
		fadingEffect: false,
		normalScrollElements: '#element1, .element2',
		scrollOverflow: false,
		scrollOverflowReset: false,
		scrollOverflowOptions: null,
		touchSensitivity: 15,
		normalScrollElementTouchThreshold: 5,
		bigSectionsDestination: null,

		//Accessibility
		keyboardScrolling: true,
		animateAnchor: true,
		recordHistory: true,

		//Design
		controlArrows: false,
		verticalCentered: true,
		//sectionsColor: ['#fffcf0'],
		//paddingTop: '3em',
		//paddingBottom: '10px',
		fixedElements: '#header, .footer',
		responsiveWidth: 0,
		responsiveHeight: 0,
		responsiveSlides: false,
		parallax: false,
		parallaxOptions: {type: 'reveal', percentage: 62, property: 'translate'},

		//Custom selectors
		sectionSelector: '.section',
		slideSelector: '.slide',

		lazyLoading: true,

		//events
		onLeave: function(index, nextIndex, direction){},
		afterLoad: function(anchorLink, index){},
		afterRender: function(){},
		afterResize: function(){},
		afterResponsive: function(isResponsive){},
		afterSlideLoad: function(anchorLink, index, slideAnchor, slideIndex){},
		onSlideLeave: function(anchorLink, index, slideIndex, direction, nextSlideIndex){}
	});

	$('.dropdown-label').click(function(event) {
		$('nav').css('background-color', '#fffcf0');
		$('.dropdown>div').addClass('is-active');
		$('nav').addClass('nav-active');
	});
	$('.dropdown-center-helper').click(function(event) {
		$('nav').css('background-color', 'transparent');
		$('.dropdown>div').removeClass('is-active');
		$('nav').removeClass('nav-active');
	});

	
	$(".project-info-button svg").click(function(event) {
		var context_ = $(".project-image");
		if ($(this).hasClass('active')) {
			$(this).removeClass('active');
		} else {
			$(this).addClass('active');
		} 
		if ($("img", context_).hasClass('hidden')) {
			$("img", context_).removeClass('hidden');
		} else {
			$("img", context_).addClass('hidden');
		}
		if ($("nav").hasClass('hidden')) {
			$("nav").removeClass('hidden');
		} else {
			$("nav").addClass('hidden');
		}
		if ($(".contact").hasClass('hidden')) {
			$(".contact").removeClass('hidden');
		} else {
			$(".contact").addClass('hidden');
		}
		if ($(".project-details", context_).hasClass('active')) {
			$(".project-details", context_).removeClass('active');
		} else {
			$(".project-details", context_).addClass('active');
		}
	});

	$('.previous-button').click(function(){
	    $.fn.fullpage.moveSlideLeft();
	});


	$('.next-button').click(function(){
	    $.fn.fullpage.moveSlideRight();
	});
});

$(window).on('load', function() {
	console.log('cc');
	$(".project-image").each(function(index, el) {
		positionProjectDetails($(this));
		console.log('cc2');
	});
	$(window).resize(function(event) {
		$(".project-image").each(function(index, el) {
			positionProjectDetails($(this));
		});
	});
});

function positionProjectDetails(projectImage) {
	var context_ = projectImage.children('div.project-image-wrapper');
	var imgWidth = $("img", context_).width();
	var imgHeight = $("img", context_).height();
	var imgTop = $("img", context_).offset().top;
	var imgLeft = $("img", context_).offset().left;
	$(".project-details", context_).width(imgWidth);
	$(".project-details", context_).height(imgHeight);
	$(".project-details", context_).offset({top: imgTop});
}